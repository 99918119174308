<template>
    <div class="row">
        <div class="col-lg-6" v-if="images?.length > 0 && imageFirst">
            <SwiperBase :imageList="images"/>
        </div>
        <div :class="`col`" :style="`${images?.length > 0 ? `` : `justify-items: center`}`">
            <h4 v-if="name">{{ name }}</h4>
            <p v-if="status || sourceControl" class="mb-0">{{ status }} - <span :class="getSourceControlColor(sourceControl)">{{ sourceControl }}</span></p>
            <slot name="extra"></slot>
            <!-- <slot name="description">
            </slot> -->
            <div :class="`row mt-2 d-flex gap-2 justify-content-center ${imageFirst ? `justify-content-lg-start` : `justify-content-lg-end`}`">
                <template v-for="(tag, index) in tags" :key="index">
                    <button disabled type="button" class="btn btn-outline-success w-auto">
                        {{ tag.name }}
                        <img v-if="tag.img" :src="tag.img" :alt="tag.name" class="tag-icon"/>
                    </button>
                </template>
            </div>
        </div>
        <div class="col-lg-6" v-if="images?.length > 0 && !imageFirst">
            <SwiperBase :imageList="images"/>
        </div>
    </div>
</template>

<script>
import SwiperBase from './SwiperBase.vue'

export default {
    name: 'ProjectBase',
    props: {
        name: {
            type: String,
            required: false
        },
        status: {
            type: String,
            required: false
        },
        sourceControl: {
            type: String,
            required: false
        },
        tags: {
            type: Array,
            required: false
        },
        images: {
            type: Array,
            required: false
        },
        imageFirst: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data: function() {
        return {
        }
    },
    methods: {
        getSourceControlColor(sourceControl) {
            switch(sourceControl?.toLowerCase()) {
                case 'closed source':
                    return 'text-danger'
                case 'open source':
                    return 'text-primary'
                case 'nla':
                    return 'text-warning'
                default:
                    return 'text-secondary'
            }
        }
    },
    components: {
      SwiperBase
    },
    setup() {
      return {
      };
    },
}
</script>

<style>
    .watch-pic {
        border-radius: 10rem;
        border: 0.3vw solid rgb(46, 46, 46);
        box-shadow: 0 0 3px 9px rgb(65, 65, 65) inset, 0 0 3px 2px rgb(48, 48, 48);
    }
</style>

<style scoped>
    .mySwiper img {
        max-width: 20rem;
        max-height: 20rem;
    }

    @media (max-width: 768px) {
        .mySwiper img {
            margin-top: 1rem;
        }
    }
</style>