<template>
    <div ref="swiperModal" class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ headerTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                    <swiper
                        ref="mySwiper"
                        :direction="'horizontal'"
                        :centeredSlides="true"
                        :navigation="true"
                        :pagination="true"
                        :effect="'coverflow'"
                        :coverflowEffect="{
                            rotate: 90,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }"
                        :mousewheel="true"
                        :keyboard="{
                            enabled: true,
                        }"
                        :modules="modules"
                        @paginationUpdate="updateHeader"
                        class="mySwiper text-center">
                        <swiper-slide v-for="(image, index) in imageList" :key="index">
                            <img class="w-100" :src="image.src" :alt="image.alt" />
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Keyboard, Navigation, Pagination, Mousewheel, EffectCoverflow} from 'swiper/modules';
    import {Modal} from 'bootstrap';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/effect-coverflow';
    export default {
        name: 'SwiperModal',
        props: {
            imageList: {
                type: Array,
                required: true
            },
            show: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: ['close'],
        data: function() {
            return {
                headerTitle: null,
                bsmodal: null
            }
        },
        mounted() {
            this.bsmodal = new Modal(this.$refs.swiperModal);

            this.bsmodal._element.addEventListener('hidden.bs.modal', () => {
                this.$emit('close');
            });
        },
        watch: {
            show: function (val) {
                if (val) {
                    this.bsmodal.show();
                }
                else {
                    this.bsmodal.hide();
                }
            }
        },
        methods: {
            updateHeader(swiper) {
                //console.log(swiper, pageEl);
                const index = swiper.realIndex;
                this.headerTitle = this.imageList[index].alt;
            }
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        setup() {
            return {
                modules: [Keyboard, Navigation, Pagination, EffectCoverflow, Mousewheel],
            };
        },
    }
</script>

<style scoped>

</style>
