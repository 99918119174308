<template>
  <WesleyDarnell />
  <ContactCMD />
 </template>

<script>
import WesleyDarnell from './components/WesleyDarnell.vue'
import ContactCMD from './components/ContactCMD.vue'

export default {
  name: 'App',
  components: {
    WesleyDarnell,
    ContactCMD
  },
  data: function() {
    return {
    }
  },
  methods: {
  }
}
</script>
