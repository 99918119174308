<template>
    <swiper
        ref="mySwiper"
        :direction="'horizontal'"
        :centeredSlides="true"
        :navigation="true"
        :pagination="true"
        :effect="'coverflow'"
        :coverflowEffect="{
            rotate: 90,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }"
        :mousewheel="true"
        :keyboard="{
            enabled: true,
        }"
        :modules="modules"
    class="mySwiper text-center">
        <swiper-slide v-for="(image, index) in imageList" :key="index">
            <img :src="image.src" :alt="image.alt" :class="image.class" @click="openModal" />
        </swiper-slide>
    </swiper>
    <SwiperModal :imageList="imageList" :show="modalOpen" @close="modalOpen = false"/>
</template>

<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Keyboard, Navigation, Pagination, Mousewheel, EffectCoverflow} from 'swiper/modules';
    import {Modal} from 'bootstrap';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/effect-coverflow';
import SwiperModal from './SwiperModal.vue';
    export default {
        name: 'SwiperBase',
        data: function() {
            return {
                modalOpen: false
            }
        },
        mounted() {
        },
        methods: {
            openModal() {
                this.modalOpen = true;
            },
            openAsModal(evt) {
                const modal = document.createElement('div');
                modal.classList.add('modal', 'fade');
                modal.id = 'modal';
                modal.setAttribute('tabindex', '-1');
                modal.setAttribute('role', 'dialog');
                modal.setAttribute('aria-labelledby', 'modalLabel');
                modal.setAttribute('aria-hidden', 'true');
                modal.innerHTML = `
                    <div class="modal-dialog modal-dialog-centered modal-lg ">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalLabel">${evt.target.alt}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body d-flex justify-content-center">
                                <img src="${evt.target.src}" class="img-fluid" alt="${evt.target.alt}">
                            </div>
                        </div>
                    </div>
                `;
                document.body.appendChild(modal);
                const bsmodal = new Modal(document.getElementById('modal'));
                //Delete element on close
                bsmodal._element.addEventListener('hidden.bs.modal', function () {
                    document.body.removeChild(modal);
                });

                bsmodal.show();
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            SwiperModal
        },
        props: ['imageList'],
        setup() {
            return {
                modules: [Keyboard, Navigation, Pagination, EffectCoverflow, Mousewheel],
            };
        },
    }
</script>

<style scoped>
    .mySwiper img {
        max-width: 20rem;
        max-height: 20rem;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .mySwiper img {
            margin-top: 1rem;
        }
    }
</style>
