<template>
  <div class="container">
    <div class="row heading">
      <div class="col">
        <h1>Wesley Darnell</h1>
      </div>
      <div class="col-sm">
        <span class="text-danger">//</span>
      </div>
      <div class="col">
        <h1>WWDEV</h1>
      </div>
    </div>
    <div class="row links">
      <div class="col">
          <a href="https://www.linkedin.com/in/wesleydarnell" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/LinkedIn-0077B5?style=for-the-badge&logo=linkedin&logoColor=white&logoHeight=8" alt="LinkedIn">
          </a>
        <!--Github-->
          <a href="https://github.com/wesleydarnell" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=github&logoColor=white&logoHeight=8" alt="GitHub">
          </a>
      </div>
      <div class="col-sm subtitle">
        <h2>Software Developer</h2>
      </div>
      <div class="col">
        <!-- Playstore-->
          <a href="https://play.google.com/store/apps/developer?id=WWDEV" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/PlayStore-414141?style=for-the-badge&logo=google-play&logoColor=white&logoHeight=8" alt="PlayStore">
          </a>
          <!-- Thingiverse -->
          <a href="https://www.thingiverse.com/wesleydarnell/designs" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/Thingiverse-00ABE5?style=for-the-badge&logo=thingiverse&logoColor=white&logoHeight=8" alt="Thingiverse">
          </a>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-8">
        <p class="bio">
          I am a developer who is highly motivated and passionate for all things tech.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 projects">
        <h2>Projects</h2>
        <table class="table table-striped" style="table-layout: fixed" ref="projects">
          <thead>
            <tr>
              <th class="border-0 p-0">
                <div class="input-group">
                  <vue-multiselect class="form-control p-0" placeholder="Select Tags" :options="tagOptions" v-model="tagsSelected" :multiple="true" label="name" track-by="name">
                    <template #tag="props">
                      <div class="multiselect__tag">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img v-if="props.option.img" class="option__image ms-2" :src="props.option.img" :alt="props.option.name"/>
                        <i tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                      </div>
                    </template>
                    <template #option="props">
                      <div class="option__desc d-flex gap-1">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img v-if="props.option.img" class="option__image" :src="props.option.img" :alt="props.option.name"/>
                      </div>
                    </template>
                  </vue-multiselect>
                  <input type="text" class="form-control" placeholder="Search Projects" v-model="searchProjectsText">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr><!-- Shift bootstrap table striping --></tr>
            <template v-for="(project, index) in filteredProjects" :key="index">
              <tr>
                <td>
                  <Project
                    :id="project.id"
                    :name="project.name"
                    :status="project.status"
                    :sourceControl="project.sourceControl"
                    :images="project.images"
                    :tags="tagOptions.filter(tag => project.tags.includes(tag.name))"
                    :imageFirst="index % 2 !== 0"
                  >
                    <template v-slot:extra>
                      <div v-html="project.extraHtml"></div>
                    </template>
                    <template v-slot:description>
                      <div v-html="project.description"></div>
                    </template>
                  </Project>
                </td>
              </tr>
            </template>
            <tr v-if="filteredProjects.length === 0">
              <td>
                <h3>No Projects Found for current search</h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Project from './projects/ProjectBase.vue'

  export default {
    name: 'WesleyDarnell',
    components: {
      Project,
    },
    data: function() {
      return {
        tagsSelected: [],
        searchProjectsText: '',
        projects: [
          {
            name: 'Retro Digital Dash Watch Face',
            id: 'retro-digital-dash',
            status: 'Maintenance',
            sourceControl: 'Closed Source',
            extraHtml: '<a href="https://play.google.com/store/apps/details?id=com.wwdev.DigitalDash" target="_blank">Download from the playstore here!</a><br><a class="pb-3" href="/privacypolicy">Privacy Policy</a>',
            tags: ['Android', 'Wear OS', 'Watch Face Studio'],
            description: 'A retro digital watch face for Wear OS.',
            images: [{
                src: new URL("@/assets/images/wf_img.png", import.meta.url),
                alt: "Watch Face Image",
            },
            {
                src: new URL("@/assets/images/retro_digital_dash.png", import.meta.url),
                alt: "Retro Digital Dash"      ,
                class: "watch-pic"              
            }]
          },
          {
            name: 'FirstVu PRO Utility - Android',
            id: 'fvpro-util-android',
            status: 'In Progress',
            sourceControl: 'Closed Source',
            tags: ['ADB', 'Android', 'Jetpack Compose', 'Kotlin'],
            description: 'A utility app for the FVPRO.',
            images: [
            ]
          },
          {
            name: 'FirstVu PRO Utility - Desktop',
            id: 'fvpro-util-desktop',
            status: 'In Progress',
            sourceControl: 'Closed Source',
            tags: ['WinForms', 'ADB', 'Android', 'SQLite', 'C#'],
            description: 'A utility app for the FVPRO.',
            images: [
                {
                src: new URL("@/assets/images/proutility/FirstVuPro_Utility.jpg", import.meta.url),
                alt: "FirstVu PRO Utility Home View",
                },
                {
                    src: new URL("@/assets/images/proutility/FirstVuPro_Utility2.jpg", import.meta.url),
                    alt: "FirstVu PRO Utility Device Log View",
                    class: ""              
                },
                {
                src: new URL("@/assets/images/proutility/FirstVuPro_Utility3.jpg", import.meta.url),
                alt: "FirstVu PRO Utility Device Live View With Buttons",
                },
                {
                src: new URL("@/assets/images/proutility/FirstVuPro_Utility4.jpg", import.meta.url),
                alt: "FirstVu PRO Utility Body Camera Info View",
                },
                {
                src: new URL("@/assets/images/proutility/FirstVuPro_Utility5.jpg", import.meta.url),
                alt: "FirstVu PRO Utility Docking Station Slot View",
                },
                {
                src: new URL("@/assets/images/proutility/FirstVuPro_Utility6.jpg", import.meta.url),
                alt: "FirstVu PRO Utility Event List (Docking Station)",
                }
            ]
          },
          {
            name: 'EVO Remote Android App',
            id: 'evo-remote',
            status: 'Proof of concept',
            sourceControl: 'Closed Source',
            tags: ['Android', 'ADB', 'Kotlin', 'JetPack Compose'],
            description: `Android app for EVO-Web platform. Provides a native app and mobile experience otherwise not available.
            Utilizing public rest API. Provides means to start recordings remotely on devices, view videos, and view live GPS location.`,
            images: [
            ]
          },
          {
            name: 'Digital Ally University',
            id: 'dau',
            status: 'Offline',
            sourceControl: 'NLA',
            tags: ['HTML', 'CSS', 'JavaScript', 'Python', 'Google Cloud', 'Google Course Builder'],
            description: `Training course website built on top the now deprecated Google Course Builder (GCB) system.
            GCB was customized to fit our needs, updating to company branding and needed functionality. 
            Utilized by Digital Ally for internal and external training. Site offline as of 2024.`,
            images: [
              {
                src: new URL("@/assets/images/DAU/DAU Home.jpg", import.meta.url),
                alt: "Digital Ally University Home Page",
              },
              {
                src: new URL("@/assets/images/DAU/DAU Example.jpg", import.meta.url),
                alt: "Digital Ally University Example Course",
              }
            ]
          }
        ],
        tagOptions: [
          { name: 'ADB', img: 'https://img.shields.io/static/v1?message=%20&logo=android&color=3ddc84&logoColor=white&label=%20' },
          { name: 'Android Studio', img: 'https://img.shields.io/static/v1?message=%20&logo=androidstudio&color=1182c3&logoColor=white&label=%20' },
          { name: 'Android', img: 'https://img.shields.io/static/v1?message=%20&logo=android&color=3ddc84&logoColor=white&label=%20' },
          { name: 'Bootstrap', img: 'https://img.shields.io/static/v1?message=%20&logo=bootstrap&color=563d7c&logoColor=white&label=%20' },
          { name: 'C#', img: null },
          { name: 'CSS', img: 'https://img.shields.io/static/v1?message=%20&logo=css3&color#1572b6&logoColor=white&label=%20' },
          { name: 'Express', img: 'https://img.shields.io/static/v1?message=%20&logo=express&color=000000&logoColor=white&label=%20' },
          { name: 'Git', img: 'https://img.shields.io/static/v1?message=%20&logo=git&color=f34f29&logoColor=white&label=%20' },
          { name: 'Google', img: 'https://img.shields.io/static/v1?message=%20&logo=google&color=4285F4&logoColor=white&label=%20' },
          { name: 'Google Course Builder', img: 'https://img.shields.io/static/v1?message=%20&logo=google&color=4285F4&logoColor=white&label=%20' },
          { name: 'Google Cloud', img: 'https://img.shields.io/static/v1?message=%20&logo=googlecloud&color=4285F4&logoColor=white&label=%20' },
          { name: 'HTML', img: 'https://img.shields.io/static/v1?message=%20&logo=html5&color=e44d26&logoColor=white&label=%20' },
          { name: 'Java', img: null },
          { name: 'JavaScript', img: 'https://img.shields.io/static/v1?message=%20&logo=javascript&color=F7DF1E&logoColor=black&label=%20' },
          { name: 'Kotlin', img: 'https://img.shields.io/static/v1?message=%20&logo=kotlin&color=7F52FF&logoColor=white&label=%20' },
          { name: 'Node.js', img: 'https://img.shields.io/static/v1?message=%20&logo=node.js&color=339933&logoColor=white&label=%20' },
          { name: 'REST API', img: null },
          { name: 'SQL', img: null },
          { name: 'SQLite', img: 'https://img.shields.io/static/v1?message=%20&logo=sqlite&color=003B57&logoColor=white&label=%20' },
          { name: 'Visual Studio', img: null },
          { name: 'VS Code', img: null },
          { name: 'Vue.js', img: 'https://img.shields.io/static/v1?message=%20&logo=vue.js&color=4FC08D&logoColor=white&label=%20' },
          { name: 'Wear OS', img: 'https://img.shields.io/static/v1?message=%20&logo=wearos&color=4285F4&logoColor=white&label=%20' },
          { name: 'Watch Face Studio', img: 'https://img.shields.io/static/v1?message=%20&logo=samsung&color=1182c3&logoColor=white&label=%20' },
          { name: 'WinForms', img: null },
          { name: 'My SQL', img: 'https://img.shields.io/static/v1?message=%20&logo=mysql&color=4479A1&logoColor=white&label=%20' },
          { name: 'PostGres SQL', img: 'https://img.shields.io/static/v1?message=%20&logo=postgresql&color=336791&logoColor=white&label=%20' }
        ]
      }
    },
    methods: {
    },
    computed: {
      filteredProjects() {
        return this.projects.filter(project =>
          (this.tagsSelected.length === 0 && this.searchProjectsText.length === 0) ||
          this.tagsSelected.every(tag => project.tags.includes(tag.name)) &&
          project.name.toLowerCase().includes(this.searchProjectsText.toLowerCase())
        )
      }
    }
  }

</script>
<style scoped>
  .watch-pic {
    border-radius: 10rem;
    border: 0.3vw solid rgb(46, 46, 46);
    box-shadow: 0 0 3px 9px rgb(65, 65, 65) inset, 0 0 3px 2px rgb(48, 48, 48);
  }
</style>
<style>
.col {
  place-self: center;
}

.col:first-child {
  text-align: right;
}

.col:last-child {
  text-align: left;
}

.heading .col:first-child, .heading .col:last-child, .projects h2{
  border-bottom: 2px solid var(--highlight);
}

.heading h1 {
  font-size: 3rem;
  text-wrap: nowrap;
}

.heading span {
  font-size: 5rem;
}

.heading h2 {
  text-align: left;
}

.subtitle {
  align-self: center;
}

.subtitle h2 {
  margin: 0;
}

.bio {
  border-bottom: 3px solid #8080805e;
  text-shadow: 0px 3px 16px black;
}

.projects h2 {
  color: var(--primary-color);
}

ul {
  list-style-type: none;
}

@media (max-width: 576px) {
  .subtitle {
    order: -1!important;
  } 

  .heading .col:first-child {
    border-bottom: none;
  }

  .links .col:first-child {
    flex: 100%;
  }

  .heading h1 {
    text-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .links .col, .heading .col {
    text-align: center !important;
  }

  .projects .row .col:first-child {
    flex: 100%
  }
}
@media (max-width: 990px) {
  .projects .col {
    text-align: center !important;
  }
}
</style>